import { createSelector } from "@ngrx/store";
import { AppState } from "../state.interface";

export const selectMonitoringState = (state: AppState) => state.monitoring;
export const selectPvState = (state: AppState) => state.monitoring.pvData;
export const selectPvError = (state: AppState) => state.monitoring.error;
export const selectMonthSate = (state: AppState) => state.monitoring.month;

export const selectPageView = createSelector(
    selectPvState,
    (pvData) => {
        // console.log('pv셀렉터', pvData);
        return pvData.map(d => {
            return {...d};
        });
    }
);

export const selectMonth = createSelector(
    selectMonthSate,
    (month) => {
        // console.log('month셀렉터', month);
        return month;
    }
);